import { useEffect } from 'react'

/* 
    This hook can be used for executing callback when user clicks outside reference element
    Parameters
    """"""""""
    ref: The reference element for click outside check
    callback: A callback function that is to be executed when a click outside reference element is detected
    closeCondition: If this parameter is present, callback function will be executed only based on the condition.
                    This can either be boolean value or a function that returns a boolean.
    onCondition: This paremeter can be used to determine whether or not to listen to outside click
*/
export default function useGlobalClickOutside(ref, callback, closeCondition = null, onCondition = true) {
  let hasMoved = false

  const setMovementFlag = () => {
    hasMoved = true
  }

  const resetMovementFlag = () => {
    hasMoved = false
  }

  function pointerUpHandler(event) {
    if (event.pointerType === 'mouse' || (event.pointerType !== 'mouse' && !hasMoved)) {
      if (!!ref.current && !ref.current.contains(event.target) && !(event.path || event.composedPath())?.includes(ref.current)) {
        if (!!closeCondition) {
          let conditionPass = true
          if (typeof closeCondition === 'function') {
            conditionPass = closeCondition(event)
          } else if (typeof closeCondition === 'boolean') {
            conditionPass = closeCondition
          }

          if (conditionPass) {
            callback()
          }

          return
        }

        callback()
      }
    }
  }

  useEffect(() => {
    if (onCondition) {
      document.addEventListener('pointerup', pointerUpHandler)
      document.addEventListener('pointermove', setMovementFlag, { passive: true })
      document.addEventListener('pointerdown', resetMovementFlag, { passive: true })
    } else {
      document.removeEventListener('pointerup', pointerUpHandler)
      document.removeEventListener('pointermove', setMovementFlag)
      document.removeEventListener('pointerdown', resetMovementFlag)
    }

    return () => {
      document.removeEventListener('pointerup', pointerUpHandler)
      document.removeEventListener('pointermove', setMovementFlag)
      document.removeEventListener('pointerdown', resetMovementFlag)
    }
  }, [ref, callback, onCondition, closeCondition])
}
