import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { getTopOrdersListURL } from '@/api-client/myaccount/get-top-orders-list-url'
import { LayoutSources } from '@/constants'
import { MenuItemType } from '@/components/side-nav/constants'
import { MyAccountConstants } from '@/containers/myaccount/constants'

export default function useMenuItems() {
  const { mutateAsync: getTopOrderedListURLAsync } = useMutation(getTopOrdersListURL)

  /* Perform the fetch opertaion and modify the l2 menu item to match with the normal l2 items action and type */
  const getTopOrderedListComputedMenuItem = (l2Item) => {
    return getTopOrderedListURLAsync().then((data) => {
      if (!!data?.url && data.url !== '#Auto') {
        return { status: true, computedL2Item: { ...l2Item, type: MenuItemType.route, route: data.url } }
      }
      return { status: false }
    })
  }

  /*
    All the custom function should return values in the following format
    If the item should be added to menu: { status: true, computedL2Item: {l2 Item with modifed type and related data}}
    If the item should be removed from menu: { status: false }
  */
  const customRunTimeMenuComputation = (l2Item) => {
    /* Modify this switch case to add cases where menu items visibility or action has to be determined based on some API response */
    switch (l2Item.action) {
      case MyAccountConstants.actions.topOrderedList:
        return getTopOrderedListComputedMenuItem(l2Item)
      default:
        return Promise.resolve({ status: false })
    }
  }

  /* Each l2 item has to be reduced to an array of eligilble l2 items by permissions and run-time computations */
  const processL2Items = (menuItemL2, permissions) => {
    return menuItemL2.reduce((computedL2ItemsPromise, l2Item) => {
      return computedL2ItemsPromise.then((computedL2Items) => {
        let permissionFilterPass = true

        // Check whether the user has all the permissions required for the l2Item. If yes, proceed with  further processing, else remove the item
        if (l2Item.permissions && Array.isArray(l2Item.permissions)) {
          permissionFilterPass = l2Item.permissions.every((permission) => permissions[permission])
        }

        // If the item is eligible by permission, check for any runTime checking
        if (permissionFilterPass) {
          // Compute 'runTime' l2 types and add if success, else add the l2 item unchanged
          if (l2Item.type === MenuItemType.runTime) {
            return customRunTimeMenuComputation(l2Item).then(({ status, computedL2Item } = {}) => {
              if (status) {
                computedL2Items.push(computedL2Item)
              }

              return computedL2Items
            })
          } else {
            computedL2Items.push(l2Item)
          }
        }

        return computedL2Items
      })
    }, Promise.resolve([]))
  }

  /* Each l1 item has to be checked to see if atleast one of the l2 items in it is eligible to be displayed to the user */
  const processL1Items = (menuItemL1, permissions) => {
    return processL2Items(menuItemL1.l2, permissions).then((computedL2Items) => {
      // If there are eligible l2 items return the computed l1 object, else return null
      if (computedL2Items?.length > 0) {
        return {
          l1: {
            ...menuItemL1,
            l2: computedL2Items,
          },
        }
      }

      return null
    })
  }

  /* Reduce the entire menu array to contain only eligible menu items to be displayed */
  const processRunTimeAndPermissionFilterMenuItems = (ogMenu = [], permissions = {}) => {
    return ogMenu.reduce((eligibleMenuItemsPromise, menuItem) => {
      return eligibleMenuItemsPromise.then((menuItems) => {
        return processL1Items(menuItem.l1, permissions).then((processedL1Item) => {
          // ProcessedL1Item will be null if there are no l2 items in it, hence those are skipped from being displayed
          if (!!processedL1Item) {
            menuItems.push(processedL1Item)
          }

          return menuItems
        })
      })
    }, Promise.resolve([]))
  }

  const getMenuItems = (source, permissions) => {
    switch (source) {
      case LayoutSources.myAccount:
        return processRunTimeAndPermissionFilterMenuItems(MyAccountConstants.menu, permissions)
      default:
        return Promise.resolve([])
    }
  }

  return [
    useMemo(
      () => ({
        getMenuItems,
      }),
      [getMenuItems],
    ),
  ]
}
