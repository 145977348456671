import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import getBackgroundColor from '@/utils/get-bg-color'
import { ROUTES } from '@/constants'
import { useSideNav } from '@/contexts/side-nav'
import { useAuth } from '@/contexts/auth'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import useMenuItems from '@/hooks/use-menu-items'
import Breadcrumbs from '../breadcrumbs'
import Loader from '../loader'
import SideNav from '../side-nav'

const TwoColumnLayout = ({ sideNav, children }) => {
  const router = useRouter()
  const { deviceTypeBasedOnWidth } = useDeviceDetect()
  const [{ getMenuItems }] = useMenuItems()
  const [{ permissions }] = useAuth()
  const { sideNavExpanded, activeNavItem, handleNavClick } = useSideNav()
  const { t } = useTranslation(['sidenav'])

  const [menu, setMenu] = useState([])

  const bgColor = getBackgroundColor(router?.pathname)
  const showBreadcrumb = !!!sideNav?.excludeBreadcrumbs && !!activeNavItem?.breadcrumb

  const renderBreadCrumbs = () => {
    if (showBreadcrumb) {
      return <Breadcrumbs breadcrumbData={[{ text: t('wca.home'), path: ROUTES.Home }, ...(activeNavItem?.breadcrumb ?? [])]} />
    }
  }

  const computeBreadCrumb = (menuItemL1, menuItemL2) => {
    let breadCrumb = []
    if (!!menuItemL1.route) {
      breadCrumb.push({ text: t(menuItemL1.title), path: t(menuItemL1.route) })
    }
    breadCrumb.push({ text: t(menuItemL2.title), path: t(menuItemL2.route) })

    return breadCrumb
  }

  const translateMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
      // Translate title for l1
      const updatedL1 = { ...menuItem.l1, title: t(menuItem.l1.title) }

      // Translate titles for l2 if present
      if (updatedL1.l2 && Array.isArray(updatedL1.l2)) {
        updatedL1.l2 = updatedL1.l2.map((l2Item) => {
          const translatedMenuItem = {
            ...l2Item,
            l1: updatedL1.id,
            title: t(l2Item.title),
          }

          // Item highlight is required for items that has 'route'
          // If current route matches with the current l2 item route, set it as active item and compute breadcrumb
          if (!!l2Item.route && activeNavItem?.route !== l2Item.route && router?.pathname?.toLowerCase()?.endsWith(l2Item?.route.toLowerCase())) {
            handleNavClick({ ...translatedMenuItem, breadcrumb: computeBreadCrumb(menuItem, l2Item) })
          }

          return translatedMenuItem
        })
      }

      return { l1: updatedL1 }
    })
  }

  useEffect(() => {
    if (sideNav?.source && Object.keys(permissions)?.length > 0 && !!!activeNavItem) {
      getMenuItems(sideNav?.source, permissions).then((data) => {
        /* Once raw menu items are fetched after permission filtering, 
           the texts has to be translated and breadcrumbs could be computed for eligible items */
        if (Array.isArray(data) && !!data?.length) {
          setMenu(translateMenuItems(data))
        }
      })
    }
  }, [permissions])

  return (
    <>
      {!!menu?.length ? (
        <div className={classNames('main-content flex justifyEnd', { flexDirectionColumnReverse: deviceTypeBasedOnWidth.mobile, flexDirectionRowReverse: !deviceTypeBasedOnWidth.mobile })}>
          <div className='content flex flexDirectionColumn'>
            {!deviceTypeBasedOnWidth.mobile && renderBreadCrumbs()}
            <main>{children}</main>
          </div>
          {!!sideNav?.source && <SideNav menuItems={menu} />}
          {deviceTypeBasedOnWidth.mobile && renderBreadCrumbs()}
        </div>
      ) : (
        <div className='main-content'>
          <Loader fullPage />
          <main></main>
        </div>
      )}

      <style jsx global>{`
        body {
          background-color: ${bgColor};
        }

        .mainWrapper {
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;
          background-color: ${bgColor};

          .main-content {
            padding: 2rem 0 5rem 0;
            gap: max(calc(50% - 47.45rem - ${sideNavExpanded ? '27.1rem' : '4.8rem'}), 3rem); /*Adjusting the gap for the content to fit at center with a minimum gap as 3rem */

            .content {
              width: 100%;
              max-width: 94.9rem;
              main {
                min-height: 50vh;
              }
            }
          }
        }

        @media (max-width: 1440px) and (min-width: 1280px) {
          .mainWrapper {
            .main-content {
              gap: max(calc(50% - 47.45rem - ${sideNavExpanded ? '27.1rem' : '4.8rem'}), 2rem); /*Adjusting the gap for the content to fit at center with a minimum gap as 2rem */
            }
          }
        }

        @media (max-width: 1280px) and (min-width: 1280px) {
          .mainWrapper {
            .main-content {
              margin: 0 2rem;
            }
          }
        }

        @media (max-width: 1279px) and (min-width: 1025px) {
          .mainWrapper {
            .main-content {
              gap: max(calc(50% - 47.45rem - 4.8rem), 2rem); /*Adjusting the gap for the content to fit at center with a minimum gap as 2rem */
              margin: 0 2rem;
            }
          }
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          .mainWrapper {
            .main-content {
              gap: 2rem;
              margin: 0 1rem;
            }
          }
        }

        @media (max-width: 767px) {
          .mainWrapper {
            .main-content {
              margin: 0 1rem;
              gap: 1rem;
              .side-nav {
                width: 100%;
              }

              .content {
                max-width: 100%;
                main {
                  padding: 0;
                  width: 100%;
                }
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(TwoColumnLayout), {
  ssr: false,
})
