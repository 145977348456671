import React, { createContext, useState, useContext, useMemo, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useMutation } from '@tanstack/react-query'
import { elementIsVisibleInViewportByPercentage } from '@/utils/element-is-visible-in-viewport-by-percentage'
import { setMyAccountMenuState } from '@/api-client/myaccount/set-menu-state'
import SubMenuPopup from '@/components/side-nav/sub-menu-popup'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import useDebouncedCallback from '@/hooks/use-debounced-callback'
import useGlobalClickOutside from '@/hooks/use-global-clickoutside'
import useHorizontalScrollGradient from '@/hooks/use-horizontal-scroll-gradient'
import { useAuth } from './auth'

const SideNavContext = createContext()

export const useSideNav = () => {
  return useContext(SideNavContext)
}

export const SideNavProvider = ({ children }) => {
  const { deviceTypeBasedOnWidth } = useDeviceDetect()
  const { handleScrollGradients } = useHorizontalScrollGradient()
  const [{ activeProfile }] = useAuth()
  const setMenuExpandedState = useDebouncedCallback(setMenuExpandedStateRaw, 250, [setMenuExpandedStateRaw])

  const [showToggle, setShowToggle] = useState(shouldShowToggleByWidth())
  const [profileMenuState, setProfileMenuState] = useState(activeProfile?.myAccountMenuState)
  const [sideNavExpanded, setSideNavExpanded] = useState(true)
  const [activeNavItem, setActiveNavItem] = useState(null)
  const [openL1, setOpenL1] = useState({})
  const [menuPopupState, setMenuPopupState] = useState('close')
  const [navItemClickDetect, setNavItemClickDetect] = useState({})

  const sideNavRef = useRef(null)
  const menuPopupRef = useRef(null)

  useGlobalClickOutside(menuPopupRef, clearAllPoupMenu, shouldCloseOnOutsidePopupClick, menuPopupState === 'open')

  const handleNavClick = (item) => {
    if (!!!item.avoidActiveState) {
      setActiveNavItem(item)
    }

    setNavItemClickDetect({ click_id: Math.random(), click_item: item })
  }

  const renderMenuPopup = (activeItem, appendToElement = document.body, referenceElement = null) => {
    if (!!!activeItem?.id) return

    if (activeItem.id === openL1.item?.id) {
      clearAllPoupMenu()
    } else {
      updateMenuPopupState('open', { item: activeItem, appendToElement: appendToElement, referenceElement: referenceElement })
    }
  }

  const applyMenuGradients = () => {
    handleScrollGradients('light', sideNavRef.current)
  }

  const handleResize = () => {
    clearAllPoupMenu()
    setShowToggle(shouldShowToggleByWidth())
  }

  const { mutate: updateProfileSideNavExpanded } = useMutation(setMyAccountMenuState)

  function shouldCloseOnOutsidePopupClick(event) {
    if (!sideNavExpanded && !!openL1?.item?.id && !!event) {
      const activeHeader = event.target?.closest('.menu-item-l1 div.header')
      if (!!activeHeader || !!!event.target?.closest('div.menu-item-l1-popup')) {
        return true
      }
    }

    return false
  }

  function updateMenuPopupState(state, activeItem = {}) {
    setMenuPopupState('inprogress')
    setTimeout(() => {
      setMenuPopupState(state)
      setOpenL1(activeItem)
    }, 300)
  }

  function clearAllPoupMenu() {
    updateMenuPopupState('close')
  }

  function shouldShowToggleByWidth() {
    return typeof window !== 'undefined' && window.matchMedia('(min-width: 1280px)').matches
  }

  function setMenuExpandedStateRaw(newState) {
    if (showToggle && newState !== profileMenuState) {
      updateProfileSideNavExpanded(newState)
      setProfileMenuState(newState)
    } else {
      setSideNavExpanded(newState)
    }
  }

  function updateOpenPopupTopPosition() {
    if (!!sideNavRef.current && !!menuPopupRef.current) {
      const { top: sideNavTop, height: sideNavHeight } = sideNavRef.current.getBoundingClientRect()
      const menuPopup = menuPopupRef.current
      menuPopup.style.top = `${sideNavTop + sideNavHeight + window.scrollY + 10}px`
    }
  }

  function updateOpenPopupLeftPosition() {
    if (!!sideNavRef.current) {
      applyMenuGradients()
      if (!!menuPopupRef.current) {
        const menuPopup = menuPopupRef.current
        if (!!openL1.referenceElement) {
          if (!elementIsVisibleInViewportByPercentage(openL1.referenceElement, 25)) {
            clearAllPoupMenu()
          } else {
            const referenceLeft = openL1.referenceElement.getBoundingClientRect().left
            const calibratedLeft = referenceLeft + menuPopup.offsetWidth > document.body.offsetWidth ? 'unset' : `${referenceLeft}px`
            menuPopup.style.left = calibratedLeft
          }
        }
      }
    }
  }

  useEffect(() => {
    setOpenL1({})
  }, [sideNavExpanded])

  useEffect(() => {
    setSideNavExpanded(showToggle ? profileMenuState : false)
  }, [showToggle])

  useEffect(() => {
    setSideNavExpanded(profileMenuState && !!shouldShowToggleByWidth())
  }, [profileMenuState])

  useEffect(() => {
    if (activeProfile?.myAccountMenuState != null) {
      setProfileMenuState(activeProfile.myAccountMenuState)
    }
  }, [activeProfile?.myAccountMenuState])

  useEffect(() => {
    if (deviceTypeBasedOnWidth.mobile) {
      updateOpenPopupLeftPosition()
      sideNavRef.current?.addEventListener('scroll', updateOpenPopupLeftPosition)

      if (menuPopupState === 'open') {
        setTimeout(() => {
          updateOpenPopupTopPosition()
        }, 0)
        document.addEventListener('scroll', updateOpenPopupTopPosition)
      } else {
        document.removeEventListener('scroll', updateOpenPopupTopPosition)
      }
    } else {
      document.removeEventListener('scroll', updateOpenPopupTopPosition)
      sideNavRef.current?.removeEventListener('scroll', updateOpenPopupLeftPosition)
    }

    return () => {
      document.removeEventListener('scroll', updateOpenPopupTopPosition)
      sideNavRef.current?.removeEventListener('scroll', updateOpenPopupLeftPosition)
    }
  }, [menuPopupState, deviceTypeBasedOnWidth])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const contextValue = useMemo(() => {
    return {
      showToggle,
      sideNavRef,
      sideNavExpanded,
      setMenuExpandedState,
      openL1,
      menuPopupState,
      renderMenuPopup,
      clearAllPoupMenu,
      activeNavItem,
      handleNavClick,
      applyMenuGradients,
      navItemClickDetect,
    }
  }, [showToggle, sideNavRef, sideNavExpanded, setMenuExpandedState, openL1, menuPopupState, renderMenuPopup, clearAllPoupMenu, activeNavItem, handleNavClick, applyMenuGradients, navItemClickDetect])

  return (
    <SideNavContext.Provider value={contextValue}>
      {children}
      {menuPopupState !== 'close' &&
        !!openL1.appendToElement &&
        createPortal(<SubMenuPopup l1Item={openL1.item} state={menuPopupState} referenceElement={openL1.referenceElement} menuPopupRef={menuPopupRef} />, openL1.appendToElement)}
    </SideNavContext.Provider>
  )
}
